import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_1/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
      hoverDot: mdx(
      frontmatter: {
        language: { eq: "UAA" }
        title: { eq: "czerwona-ofensywa-modal" }
      }
    ) {
      exports {
        hoverInfo {
          positionLeft
          positionTop
          insideText
          identifier
        }
      }
    },
    feliksDzierzynski:  mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "feliksDzierzynski"}}) {
      body
    },
    julianMarchlewski: mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "julianMarchlewski"}}) {
      body
    },
    feliksKon: mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "feliksKon"}}) {
      body
    },
    iwanSkworcowStiepanow: mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "czerwona-ofensywa-modal"}, selector: {eq: "iwanSkworcowStiepanow"}}) {
      body
    },
    caption:   mdx(frontmatter: {language: {eq: "UA"}, title: {eq: "slide-4-1-2"}}) {
      body
  }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
